<template>
    <div class="container-fluid">
        <!-- 기존 헤더 섹션 -->
        <div class="row">
            <div class="col-lg-12">
                <div class="d-flex flex-wrap align-items-center justify-content-between mb-4">
                    <div>
                        <h4 class="mb-3">{{title}}</h4>
                        <p class="mb-0">{{desc1}}<br> {{desc2}}</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-12">
                <b-card no-body class="shadow-sm">
                    <b-card-body>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="d-flex align-items-center">
                                    <div class="border-left border-primary border-4 pl-3">
                                        <h6 class="text-muted mb-1">총 상품</h6>
                                        <h4 class="mb-0">{{aiImageList.length}}개</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="d-flex align-items-center">
                                    <div class="border-left border-success border-4 pl-3">
                                        <h6 class="text-muted mb-1">작성 완료</h6>
                                        <h4 class="mb-0">{{completedProducts}}개</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="d-flex align-items-center">
                                    <div class="border-left border-warning border-4 pl-3">
                                        <h6 class="text-muted mb-1">작성 중</h6>
                                        <h4 class="mb-0">{{inProgressProducts}}개</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-card-body>
                </b-card>
            </div>

            <div class="col-lg-12" v-if="showlist">
                <div class="row d-flex flex-column">
                    <div class="col-6 d-flex justify-content-start mb-3">
                        <b-input-group>
                            <b-form-input
                                type="text"
                                placeholder="1688 구매링크 입력"
                                v-model="추가링크"
                                @keyup.enter="addLink"
                            ></b-form-input>
                            <b-input-group-append>
                                <b-button 
                                    variant="outline-primary"
                                    @click="addLink"
                                    :disabled="showspinner"
                                >
                                    <span v-if="!showspinner">링크추가</span>
                                    <b-spinner small v-else></b-spinner>
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </div>
                    
                    <CDataTable 
                        :headers="headers" 
                        :items="aiImageList" 
                        v-model="tableSelectedItem"
                        :show-select="true"
                        itemKey="등록번호"
                        class="col-12"
                    >
                        <template v-slot:상품명="{item}">
                            <div class="d-flex align-items-center">
                                <img 
                                    :src="item.등록이미지" 
                                    class="img-fluid rounded avatar-50 mr-3" 
                                    alt="image" 
                                    v-if="item.등록이미지"
                                >
                                <div v-if="!item.isEditingName" @click="startEditingName(item)">
                                    {{ item.상품명 || '상품명을 입력하세요' }}
                                </div>
                                <div v-else class="d-flex w-100">
                                    <b-form-input
                                        v-model="item.상품명temp"
                                        @blur="saveName(item)"
                                        @keyup.enter="saveName(item)"
                                        ref="nameInput"
                                        placeholder="상품명 입력"
                                    ></b-form-input>
                                </div>
                            </div>
                        </template>

                        <template v-slot:구매링크="{item}">
                            <div v-if="item.구매링크 !== undefined">
                                <a :href="item.구매링크" target="_blank">{{item.구매링크}}</a>
                                <div v-if="item.showspinner">
                                    <b-spinner small></b-spinner>
                                </div>
                            </div>
                            <div v-else>
                                <b-form-input
                                    placeholder="구매링크입력"
                                    v-model="item.구매링크init"
                                    @change="matchoption1(index,item.구매링크init)"
                                ></b-form-input>
                            </div>
                        </template>

                        <template v-slot:상태="{item}">
                            <div>
                                <b-badge 
                                    :variant="getStatusVariant(item)"
                                    class="badge-pill"
                                >
                                    {{getStatusText(item)}}
                                </b-badge>
                            </div>
                        </template>

                        <template v-slot:최종수정일="{item}">
                            <span>{{formatDate(item.updatedAt || item.등록번호)}}</span>
                        </template>

                        <template v-slot:관리="{item}">
                            <div class="d-flex justify-content-center gap-2">
                                <b-button 
                                    size="sm" 
                                    variant="outline-primary" 
                                    class="mr-2"
                                    @click="editItem(item)"
                                >
                                    <i class="las la-edit"></i>
                                </b-button>
                                <b-button 
                                    size="sm" 
                                    variant="outline-danger"
                                    @click="confirmDelete(item)"
                                >
                                    <i class="las la-trash-alt"></i>
                                </b-button>
                            </div>
                            <image-editor
                                :id="'makePageModal' + item.등록번호"
                                :item="item"
                                @auto-save="handleWizardAutoSave"
                                @closed="onWizardClosed"
                            />
                        </template>
                    </CDataTable>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import CDataTable from "@/components/common/CDataTable";
import ImageEditor from '../../../components/AI/ProductDetailWizard.vue';

export default {
    name:'listproduct',
    components:{
        CDataTable,
        ImageEditor
    },
    data(){
        return{
            completedProducts: 0,
            inProgressProducts: 0,
            headers: [
                {text: '등록번호', value: '등록번호', align: 'center', width: 80, isSlot: false},
                {text: '상품명', value: '상품명', align: 'center', width: 250, isSlot: true},
                {text: '상태', value: '상태', align: 'center', width: 100, isSlot: true},
                {text: '최종수정일', value: '최종수정일', align: 'center', width: 120, isSlot: true},
                {text: '구매링크', value: '구매링크', align: 'center', width: 100, isSlot: true},
                {text: '관리', value: '관리', align: 'center', width: 100, isSlot: true}
            ],
            tableSelectedItem: [],
            selectedImageSrc: null,
            isAllDetailsSelected: false,
            추가링크:"",
            title:"AI 상세페이지",
            desc1:"1688 썸네일,옵션,상세페이지를 AI번역 및 편집할 수 있는 페이지 입니다.",
            desc2:"",
            showlist: true,
            showspinner: false,
            aiImageList:[],
            ProductList : {
                등록이미지: '',
                구매링크: '',
                옵션1_중국어: '',
                옵션2_중국어: '',
                판매구성수량: '',
                구매가능상태: '',
            },
            isTranslating: false,
            currentRegNum: null,
            currentIndex: 0,
            currentType: 0,
            showImageEditor: false,
            editorRequestId: null,
            editorImgUrl: null,
            translatingImages: {},
        }
    },
    mounted(){
        this.getItems();
        window.addEventListener('message', this.handleImageEditorMessage);
    },
    beforeDestroy() {
        window.removeEventListener('message', this.handleImageEditorMessage);
    },
    methods: {
        // 자식으로부터 auto-save 이벤트 수신 시, 처리할 로직
        handleWizardAutoSave(item) {
            console.log('[부모] 자식에서 auto-save 이벤트. item:', item);
            // 자식이 item에 wizardData를 이미 담았다고 가정
            this.saveItem(item, false);
        },
        onWizardClosed() {
        console.log('[부모] Wizard 모달이 닫혔습니다.');
        // 닫힌 후 필요한 후속 처리
        },
        startEditingName(item) {
            this.$set(item, 'isEditingName', true);
            this.$set(item, '상품명temp', item.상품명 || '');
            this.$nextTick(() => {
                if (this.$refs.nameInput) {
                    this.$refs.nameInput.focus();
                }
            });
        },

        async saveName(item) {
            if (item.상품명temp !== item.상품명) {
                item.상품명 = item.상품명temp;
                try {
                    await this.saveItem(item,true);
                } catch (error) {
                    console.error('Failed to save item name:', error);
                }
            }
            this.$set(item, 'isEditingName', false);
        },

        handleImageEditorMessage(event) {
            if (event.origin === 'https://www.xiangjifanyi.com' && event.data.name === 'XJ_IMAGE_EDITOR_URL') {
                this.updateEditedImage(event.data);
            }
        },

        updateEditedImage(data) {
            const { url, requestId, all } = data;
            const currentItem = this.aiImageList.find(e => e.등록번호 == this.currentRegNum);
            
            if (currentItem) {
                Object.keys(all).forEach(reqId => {
                    const editedUrl = all[reqId];
                    this.updateImageArray(currentItem.thumbnailImages, reqId, editedUrl);
                    this.updateImageArray(currentItem.optionImages, reqId, editedUrl);
                    this.updateImageArray(currentItem.detailImages, reqId, editedUrl);
                });

                if (this.currentType && this.currentIndex !== undefined) {
                    const currentImage = currentItem[this.currentType][this.currentIndex];
                    currentImage.img = url;
                    currentImage.translatedImg = url;
                    currentImage.transInfo = {
                        ...currentImage.transInfo,
                        RequestId: requestId
                    };
                    if (this.currentType === 'detailImages') {
                        currentItem.detailImages = [...currentItem.detailImages];
                        this.$set(this.translatingImages, this.currentIndex, false);
                    } else {
                        this.selectedImageSrc = url;
                    }
                }
            }

            this.isTranslating = false;
            this.showImageEditor = false;
        },

        updateImageArray(array, requestId, newUrl) {
            const index = array.findIndex(img => img.transInfo && img.transInfo.RequestId === requestId);
            if (index !== -1) {
                array[index].img = newUrl;
                array[index].translatedImg = newUrl;
                array[index].transInfo = {
                    ...array[index].transInfo,
                    RequestId: requestId
                };
            }
        },

        async saveItem(item,bool){
            try{
                const res = await axios.post('/api/aiPage/saveItems',[item])
                if(res.data.res == 'success'){
                    if(bool){
                        alert('저장완료')
                    }
                    return
                } else {
                    alert('저장실패 : ' + res.data.alert);
                    return
                }
            }catch(e){
                console.error('err821' + e);
                alert('저장실패(err821)')
                return 
            }
        },

        async getProductInfo(구매링크){
            const productNo = 구매링크.slice(구매링크.search('offer/')+6,구매링크.search('.html'));
            try{
                const res = await axios.post('/api/aiPage/getProductInfo',[{productId:parseInt(productNo)}])
                return res
            }catch(e){
                console.error('err821' + e);
                return false
            }
        },

        async addLink(){
            if(!this.추가링크){
                alert('1688상품 링크를 입력해 주세요.')
                return
            }
            if(!this.추가링크.includes('detail.1688.com')){
                alert('올바른 1688상품 링크를 입력해 주세요.')
                return
            }

            const res = await this.getProductInfo(this.추가링크);
            const data = res.data.result[0].result.result
            const now = new Date().getTime();

            const parser = new DOMParser();
            const doc = parser.parseFromString(data.description, 'text/html');
            const imgElements = doc.querySelectorAll('img');

            const imagePromises = Array.from(imgElements).map(img => this.loadImage(img.src));
            const results = await Promise.all(imagePromises);
            console.log(results)
            const imageSources = Array.from(imgElements).map(img => img.src);

            const thumbnail = data.productImage.images.map(e => ({
                img: e,
                selectedThumbs: false,
            }));

            const option = data.productSkuInfos
                .map(e => e.skuAttributes[0].skuImageUrl)
                .filter(e => e && e.length > 10)
                .map(e => ({
                    img: e,
                    selectedOptions: false,
                }));

            const detail = imageSources
                .filter(e => e.length > 10)
                .map(e => ({
                    img: e,
                    selectedDetails: false,
                }));

            this.aiImageList.push({
                data,
                등록번호: now,
                상품명: "",
                등록이미지: data.productImage.images[0],
                구매링크: this.추가링크.split('?')[0],
                showspinner: false,
                상세페이지: false,
                thumbnailImages: thumbnail,
                optionImages: option,
                detailImages: detail,
            });
        },

        loadImage(src) {
            return new Promise((resolve) => {
                const image = new Image();
                image.src = src;
                image.onload = () => {
                    if (image.naturalWidth > 50 && image.naturalHeight > 50) {
                        resolve(image.src);
                    } else {
                        resolve(null);
                    }
                };
                image.onerror = () => {
                    resolve(null);
                };
            });
        },

        deepClone(obj) {
            if (obj === null || typeof obj !== "object") {
                return obj
            }
            const result = Array.isArray(obj) ? [] : {}
            for (let key of Object.keys(obj)) {
                result[key] = this.deepClone(obj[key])
            }
            return result
        },

        async getAIPageList(){
            try{
                const res = await axios.post('/api/aiPage/getList',[])
                return res?.data?.result || [];
            }catch(e){
                return []
            }
        },

        async getItems() {
            this.showspinner = true;
            this.showlist = false;
            this.aiImageList = [];
            
            try {
                const res = await this.getAIPageList();
                if (res.length === 0) {
                    alert('수집된 내역이 없습니다.');
                    return;
                }
                
                this.aiImageList = res;
                this.updateProductStats();
                
            } catch (error) {
                console.error('Failed to fetch items:', error);
            } finally {
                this.$nextTick(() => {
                    this.showlist = true;
                    this.showspinner = false;
                });
            }
        },

        updateProductStats() {
            this.completedProducts = this.aiImageList.filter(item => item.상세페이지).length;
            this.inProgressProducts = this.aiImageList.length - this.completedProducts;
        },

        getStatusVariant(item) {
            if (item.상세페이지) return 'success';
            if (item.isTranslating) return 'warning';
            if (!item.상품명) return 'danger';
            return 'info';
        },

        getStatusText(item) {
            if (item.상세페이지) return '작성완료';
            if (item.isTranslating) return '번역중';
            if (!item.상품명) return '미작성';
            return '작성중';
        },

        formatDate(timestamp) {
            const date = new Date(parseInt(timestamp));
            return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
        },

        editItem(item) {
            this.$bvModal.show('makePageModal' + item.등록번호);
        },

        async confirmDelete(item) {
            if (confirm('이 상품을 삭제하시겠습니까?')) {
                try {
                    // 1) 백엔드에 삭제 요청
                    const res = await axios.post('/api/aiPage/deleteItem', {
                        _id: item._id  // _id 기준으로 삭제
                    });

                    // 2) 결과에 따라 처리
                    if (res.data.res === 'success') {
                        // 로컬 목록에서도 제거
                        const index = this.aiImageList.findIndex(
                            (i) => i.등록번호 === item.등록번호
                        );
                        if (index !== -1) {
                            this.aiImageList.splice(index, 1);
                            this.updateProductStats();
                        }

                        // 토스트나 얼럿 등
                        this.$bvToast.toast('상품이 삭제되었습니다.', {
                            title: '삭제 완료',
                            variant: 'success',
                            solid: true
                        });
                    } else {
                        this.$bvToast.toast(`삭제 실패 : ${res.data.alert}`, {
                            title: '오류',
                            variant: 'danger',
                            solid: true
                        });
                    }
                } catch (error) {
                    console.error('Delete error:', error);
                    this.$bvToast.toast('삭제 중 오류가 발생했습니다.', {
                        title: '오류',
                        variant: 'danger',
                        solid: true
                    });
                }
            }
        },
    }
}
</script>
<style>
</style>

